<template>
    <div class="sup_content website_list">
        <div class="sup_page_heading">
            <h2>Website Management</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add User'" @click="addWebsiteModal.modalState = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Order Table Start --->
            <div class="p-d-flex p-jc-between weblist-top-table-header table-border-bottom">
                <!-- <div class="sup_list_action">
                </div> -->
                <form @submit.prevent="searchData()">
                    <div class="p-d-flex p-align-left">
                        <div class="sup_header_field">
                            <label for="select_date">Date :</label>
                            <Calendar v-model="websiteSearch.dateRange" placeholder="DD.MM.YY" id="select_date" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button" inputClass="p-inputtext" :manualInput="false" :showIcon="true" plceholder="abc" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_customer_name">Customer :</label>
                            <Dropdown v-model="websiteSearch.customerUuid" :options="allCustomerData.data" id="u_customer_name" optionLabel="name" optionValue="uuid" placeholder="Select customer" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_licence_status">License Status:</label>
                            <Dropdown v-model="licenseStatus" :options="licenseStatuses" id="u_licence_status" optionLabel="name" optionValue="code" placeholder="Select status" />
                        </div>
                        <div class="sup_header_field">
                            <label for="u_is_free">Is Free</label>
                            <Dropdown v-model="LicenseIsFree" :options="isFreeWebsite" id="u_is_free" optionLabel="name" optionValue="code" placeholder="Select is free" />
                        </div>
                        <div class="sup_header_field_blank">
                            <Button type="submit" label="Search" class="p-button-success" />
                        </div>
                        <div class="sup_header_field_blank">
                            <Button label="Clear" class="p-button-secondary" @click="reloadPage()" />
                        </div>
                    </div>
                </form>
                <div class="sup_header_field_blank">
                    <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton>
                </div>
            </div>
            <!--- Show Website list Table Start --->
            
            <DataTable class="p-datatable order-data-table" :paginator="true" :value="allWebsiteData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allWebsiteData.loading" responsiveLayout="scroll" removableSort :filters="filters" v-model:selection="allWebsiteData.selectedItem" :dataKey="uuid" @row-select="selectedItem($event)" @row-select-all="selectedItem($event)">
                <template #loading>
                    Loading website data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between ">
                        <div class="sup_list_action">
                            <Button v-if="websiteDeactiveList" :label="allWebsiteData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allWebsiteData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allWebsiteData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllWebsite(allWebsiteData.activityStatus, 'websiteStatus')" />
                            <Button v-if="allWebsiteData.activityStatus !== 'active'" label="Delete All" icon="pi pi-trash" class="p-button-danger"  />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allWebsiteData.search" placeholder="Search Website" @keyup.enter="fetchAllWebsite(allWebsiteData.activityStatus, 'search')"/>
                            </span>
                            <!-- <Calendar v-model="allWebsiteData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allWebsiteData.dateRange[1] != null ? fetchAllWebsite(allWebsiteData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allWebsiteData.search !== null || allWebsiteData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllWebsite(allWebsiteData.activityStatus, 'resetFilters')"/>
                            <SplitButton v-if="websiteExportList" label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType"></SplitButton> -->
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Website found.
                </template>
                <Column v-if="allWebsiteData.activityStatus !== 'active'" selectionMode="multiple" headerStyle="width: 3rem" :exportable="false"></Column>
                <Column field="siteName" header="Site Name" headerStyle="width: 12rem" :sortable="true">
                    <template #body="slotProps">
                        <div class="site_nameurl_list">
                            <span class="sup_site_info">{{slotProps.data.siteName ? slotProps.data.siteName : 'NA'}}</span> 
                            <a :href="'https://' + [slotProps.data.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.siteUrl}}<!-- <i class="pi pi-external-link"></i> --></a>
                        </div>
                        
                    </template>
                </Column>

                <Column field="licenseKey" header="License Key" :sortable="true">
                    <!-- <template #body="slotProps">
                        <span v-if="slotProps.data.License && allWebsiteData.activityStatus === 'active'" class="sup_copy_license" :data-license="slotProps.data.License.licenseKey"><input type="text" :value="slotProps.data.License.licenseKey" readonly /><i class="pi pi-copy" @click="copyLicense($event)" v-tooltip="'Copy License!'"></i></span>
                        <span v-else>{{slotProps.data.License.licenseKey}}</span>
                    </template> -->
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban'" icon="pi pi-copy" class="p-button-secondary p-button-rounded p-button-text p-button-sm" v-tooltip="'Copy Licence Key'" @click="copyKey($event)" />
                        <InputText class="up_copy_license d-none" v-if="slotProps.data.License !== null && slotProps.data.License.status === 'auth'" type="text" :value="slotProps.data.License.licenseKey" readonly />
                    </template>
                </Column>

                <Column field="name" header="Customer" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.User ? slotProps.data.User.name : 'NA'}}
                    </template>
                </Column>

                <Column field="serviceFor" header="Service Type" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.serviceFor ? (slotProps.data.serviceFor.charAt(0).toUpperCase() + slotProps.data.serviceFor.slice(1)) : 'NA'}}
                    </template>
                </Column>

                <Column field="freeStatus" header="Is Free">
                    <template #body="slotProps">
                        <label v-if="slotProps.data.License != null" :for="slotProps.data.uuid" :class="['sup_status_switch', slotProps.data.License.isFree ? 'sup_status_active' : 'sup_status_inactive', slotProps.data.deletedAt !== null ? 'sup_status_switch_disable' : '']" >
                            <input v-if="slotProps.data.License.isFree" type="checkbox" role="switch" :id="slotProps.data.uuid" checked>
                            <input v-else type="checkbox" role="switch" :id="slotProps.data.uuid">
                            <span class="sup_switch_label" data-active="Yes" data-inactive="No" @click="switchWebsiteStatus(slotProps.data.License.uuid, slotProps.data.License.isFree, $event)"></span>
                            <span class="sup_switch_handle"></span>
                        </label>
                    </template>
                </Column>

                <Column field="authStatus" header="License Status" sortable filterField="License" sortField="License.status">
                    <template #body="slotProps">
                        <!-- <span :class="['sub_license_status', slotProps.data.License === null ? 'warning' : slotProps.data.License.status === 'auth' ? 'success' : 'danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status.charAt(0).toUpperCase() + slotProps.data.License.status.slice(1)}}</span> -->

                        <span :class="['sup_item_status', slotProps.data.License === null ? 'sup_item_status_danger' : slotProps.data.License.status === 'auth' ? 'sup_item_status_success' : 'sup_item_status_danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status.charAt(0).toUpperCase() + slotProps.data.License.status.slice(1)}}</span>
                    </template>
                </Column>

                <Column field="createdAt" header="Created On" headerStyle="width: 10rem" :sortable="true">
                    <template #body="slotProps">
                        <!-- {{slotProps.data.createdAt ? usFormatDate(slotProps.data.createdAt.split("T")[0]) : 'NA'}} {{slotProps.data.createdAt ? slotProps.data.createdAt.split("T")[1].split(".")[0] : 'NA'}} -->
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="action" header="Actions" headerStyle="width: 10rem">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-dark p-mr-1" @click="viewWebsiteById(slotProps.data, 'viewWebsite')" v-tooltip="'View More'" />
                        <Button v-if="websiteDeactive ? slotProps.data.deletedAt === null : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confimationModal(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore'" @click="confimationModal(slotProps.data, 'restore')" />
                        <!-- Respective website invoice route start -->
                        <Button v-if="websiteInvoice ? slotProps.data.deletedAt === null : false"  icon="pi pi-credit-card" class="p-button-warning p-button-rounded p-button-sm p-mr-1" v-tooltip.left="'View Invoice'" @click="$router.push({ name: 'SuperAdminOrderList', params: {id: slotProps.data.uuid} })" />
                        <!-- Respective website invoice route end -->
                    </template>
                </Column>
            </DataTable>
            <!--- Show Website list Table End --->
        </div>
        <!--- Add Website Modal Start --->
        <Dialog header="Add New website" v-model:visible="addWebsiteModal.modalState" :modal="true" :style="{width: '700px'}" @hide="closeWebsiteDialog()">
            <div class="up_main_container_sm">
                <div v-if="!addNewStep.complete" class="up_site_add_container">
                    
                    <div class="up_site_step p-mt-3 p-mb-6">
                        <ul>
                            <li :class="[addNewStep.websiteDetails ? 'active' : '']">Website Details</li>
                            <li :class="[addNewStep.payment ? 'active' : '']">Payment</li>
                        </ul>
                    </div>
                    <div v-if="addNewStep.websiteDetails" class="up_website_details">
                        <!-- <div class="p-d-flex p-justify-end p-mb-3">
                            <Button :disabled="addwebsiteData.disableButton" type="button" label="Next" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step1')" />
                        </div> -->
                        <div class="up_website_details_section up_service_type p-mb-5">
                            <h3>User</h3>
                            <div class="up_website_details_inner">
                                <div class="p-field p-col-6">
                                    <Dropdown v-model="addwebsiteData.user" :options="allUserData.data" id="u_role" optionLabel="name" optionValue="uuid" placeholder="Select a User" />
                                    <small class="p-invalid sup_text_danger" v-if="addwebsiteDataError.userEmpty">Selact a User.</small>
                                </div>
                                
                            </div>
                        </div>
                        <div v-if="!packageType.additionalService" class="up_website_details_section up_service_type p-mb-5">
                            <h3>Service Type</h3>
                            <div class="up_website_details_inner">
                                
                                <div class="up_type_col">
                                    <h4>Prime Service</h4>
                                    <div class="p-field-radiobutton" v-for="type of packageType.prime" :key="type.uuid">
                                        <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                        <label :for="type.uuid">{{type.name}}</label>
                                    </div>
                                </div>
                                <div class="up_type_col">
                                    <h4>Combo Service</h4>
                                    <div class="p-field-radiobutton" v-for="type of packageType.combo" :key="type.uuid">
                                        <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                        <label :for="type.uuid">{{type.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="up_website_details_section up_info p-mb-5">
                            <h3>Basic Info</h3>
                            <div class="up_website_details_inner">
                                <h4>Service Type</h4>
                                <div v-if="packageType.additionalService" class="p-formgroup-inline">
                                    <div class="p-field-radiobutton" v-for="type of packageType.additional" :key="type.uuid">
                                        <RadioButton :id="type.uuid" name="service" :value="type.name" v-model="addwebsiteData.service" />
                                        <label :for="type.uuid">{{type.name}}</label>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col">
                                        <label for="websiteName">Website Name<span class="text-danger">*</span></label>
                                        <InputText id="websiteName" ref="webname" type="text" v-model="addwebsiteData.name" @change="changeInputValue()" />
                                        <small class="p-invalid text-danger" v-if="addwebsiteDataError.invalidName">Website Name is In-valid.</small>
                                    </div>
                                    <div class="p-field p-col">
                                        <label for="websiteUrl">Website URL ( e.g. truabilities.com )<span class="text-danger">*</span></label>
                                        <InputText id="websiteUrl" type="text" v-model="addwebsiteData.url" @change="changeInputValue()" />
                                        <small class="p-invalid text-danger" v-if="addwebsiteDataError.invalidUrl">Website URL is In-valid.</small>
                                    </div>
                                </div>
                            </div>
                            <small class="p-invalid text-danger" v-if="apiResponse.step1Status !== 200">{{apiResponse.step1Message}}</small>
                        </div>
                        
                        <div class="p-d-flex p-justify-end p-mb-3">
                            <Button :disabled="addwebsiteData.disableButton" type="button" label="Next" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step1')" />
                        </div>
                    </div>
                    <div v-if="addNewStep.payment" class="up_payment">
                        <div class="up_payment_box">
                            <div v-if=" addNewStep.editWebsiteData !== null && addNewStep.editWebsiteData.services !== 'incompleteEdit'" class="up_additional_service">
                                <h3>Select Service</h3>
                                <div class="p-formgroup-inline">
                                    <div class="p-field-checkbox" v-for="type of packageType.additional" :key="type">
                                        <Checkbox :id="type.value" name="service" :value="type.value" v-model="addwebsiteData.additionalService" />
                                        <label :for="type.value">{{type.name}}</label>
                                    </div>
                                    <small class="p-invalid text-danger" v-if="addwebsiteDataError.additionalService">No additional service seleted.</small>
                                </div>
                            </div>
                            
                        
                            <div class="new_cardtype_list">
                            <div class="new_single_card"  v-if="userCardStatus && addwebsiteData.card">
                                <div :class="['card_inner_details', addwebsiteData.card.brand === 'Visa' ? 'visa-bg-wrapper' : addwebsiteData.card.brand === 'MasterCard' ? 'master-card-wrapper' : 'amex-wrapper' ]" :data-name="addwebsiteData.card.isPrimary ? 'Seleted' : ''">
                                    <div class="add_card_type">
                                    <div class="left">
                                        <div class="card-chip">
                                            <!-- <img :src="imageUrlFrontEnd + 'images/chip-card.png' " width="60" height="35"> -->
                                            <img src="https://truabilities-dev.s3.us-west-1.amazonaws.com/Media/Users/chip-card.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966641878&Signature=HbSgd%2BZmCtxzMWHh3i7OahILs74%3D" >
                                        </div>
                                    </div>
                                    <div class="right">Credit Card</div>
                                </div>
                                <div class="card-number-new">
                                    <span>**** **** ****</span> {{addwebsiteData.card.last4}}
                                </div>
                                <div class="card-with-auth-name">
                                    <div class="card-validity">
                                        <span class="validity-data">
                                            <span class="valid-heading">Valid</span> {{addwebsiteData.card.exp_month}}/{{addwebsiteData.card.exp_year}}
                                        </span>
                                        <div class="card-type-logo">
                                            <img :src="imageUrlFrontEnd + 'images/' + [addwebsiteData.card.brand === 'Visa' ? 'visa.jpg' : addwebsiteData.card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="60" height="35">
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            
                            <h3 v-if="!addNewStep.editWebsitePayment">Have Coupon Code?</h3>
                            <div v-if="!addNewStep.editWebsitePayment" class="up_coupon_row">
                                <div class="p-fluid p-formgrid p-grid p-align-center">
                                    <div class="p-field p-col p-mb-0">
                                        <InputText v-model="addwebsiteData.coupon" id="coupon" type="text" placeholder="Enter Coupon Code" />
                                        
                                        <small v-if="couponPayloadData.status === 200" class="text-success"><i class="pi pi-check text-success"></i>{{couponPayloadData.message}}</small>
                                        <small v-if="couponPayloadData.status === 400" class="text-danger">{{couponPayloadData.message}}</small>
                                    </div>
                                    <Button :disabled="addwebsiteData.coupon === ''" icon="pi pi-check" class="p-button-rounded p-button-primary" @click="applyCoupon()" />
                                </div>
                            </div>
                            <div class="up_payment_details p-mb-5">
                                <div class="up_payment_details_inner">
                                    <div class="up_payment_details_row p-d-flex p-align-center">
                                        <div class="up_payment_details_col p-col-9">Tier:</div>
                                        <div class="up_payment_details_col p-col-3 p-text-right">Free</div>
                                    </div>
                                    <div class="up_payment_details_row p-d-flex p-align-center">
                                        <div class="up_payment_details_col p-col-9">100 pageviews <br /> Tier Price/month:</div>
                                        <div class="up_payment_details_col p-col-3 p-text-right">$0.00</div>
                                    </div>
                                    <div class="up_payment_details_row up_payment_details_total p-d-flex p-align-center">
                                        <div class="up_payment_details_col p-col-9 p-text-right">Total Due Today:</div>
                                        <div class="up_payment_details_col p-col-3 p_payment_total_amount p-text-right">$0.00</div>
                                    </div>
                                </div>
                            </div>
                            <div class="up_payment_note">
                                <small class="p-mb-1 p-d-block">*First 100 pageviews will be free for this website.</small><br />
                                <small>**Once the pageviews cross over 100 times, it will be automatically charged monthly as per our rate tiers.</small>
                            </div>
                            <div class="up_payment_actions p-d-flex p-justify-center p-mt-5">
                                <Button icon="pi pi-check" v-if="addNewStep.editWebsiteData === null" type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('step2')" />
                                <Button icon="pi pi-check" v-else-if="addNewStep.editWebsiteData !== null && addNewStep.editWebsiteData.payment" type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('serviceAdd')" />
                                <Button icon="pi pi-angle-left" v-else type="button" label="Order" class="p-button-primary p-mr-2" @click="WebsiteAddStep('edit')" />
                                <Button type="button" icon="pi pi-angle-left" label="Back" class="p-button-info p-mr-2" @click="backtoDetails()" />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="addNewStep.complete" class="up_siteadd_complete_container">
                    <div class="up_site_success bg-success text-white p-py-2 p-px-2 p-mb-3">New website created successfully.</div>
                    <div class="up_install_process p-grid p-d-flex">
                        <div class="up_license_code p-col-5">
                            <h4>COPY AND PASTE THIS CODE</h4>
                            <div class="p-field p-col p-px-0 p-mt-2">
                                <Textarea name="licenseCode" v-model="addwebsiteData.licenseCode" cols="20" rows="5" readonly />
                            </div>
                            <Button @click="openInstallationPopup()" label="Need Help? Have experts install" class="p-button-primary up_need_help" />
                        </div>
                        <div class="up_install_instructions p-col-7">
                            <Accordion :activeIndex="0">
                                <AccordionTab header="Installation Instructions without GTM">
                                    <table class="up_website_creation">
                                        <tbody>
                                            <tr>
                                                <td>Step 1</td>
                                                <td>Copy the <code>&lt;script&gt;</code> tag.</td>
                                            </tr>
                                            <tr>
                                                <td>Step 2</td>
                                                <td>Open your file manager or any FTP client, find the <code>&lt;/body&gt;</code> tag in your website source code.</td>
                                            </tr>
                                            <tr>
                                                <td>Step 3</td>
                                                <td>Paste the <code>&lt;script&gt;</code> before the immediate closing of <code>&lt;/body&gt;</code> tag.</td>
                                            </tr>
                                            <tr>
                                                <td>Step 4</td>
                                                <td>Save this file, upload the file to server if necessary.</td>
                                            </tr>
                                            <tr>
                                                <td>Step 5</td>
                                                <td>Reload the website after clearing all you cookies and cached data from browser and from your server if it has caching mechanism on place.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionTab>
                                <AccordionTab header="Installation Instructions with GTM">
                                    <table class="up_website_creation">
                                        <tbody>
                                            <tr>
                                                <td>Step 1</td>
                                                <td>Copy the code snippet and place within your GTM console.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                    
                </div>
            </div>
        </Dialog>
        <!--- Add Website Modal End --->
        <!--- View Website Modal Start --->
        <Dialog v-model:visible="viewWebsiteModal.modalState" :modal="true" :style="{width: '600px'}">
            <template #header>
                <span class="p-dialog-title">{{viewWebsiteModal.modaldata.siteName.charAt(0).toUpperCase() + viewWebsiteModal.modaldata.siteName.slice(1)}} Site Details</span>
            </template>
            <!-- {{viewWebsiteModal.modaldata}} -->
            <div class="sup_dialog_content sup_website_info">
                <div class="sup_website_info_section">
                    <div class="sup_section_title"><i class="pi pi-globe"></i>Site info:</div>
                    <ul>
                        <li><strong>Name:</strong> {{viewWebsiteModal.modaldata.siteName.charAt(0).toUpperCase() + viewWebsiteModal.modaldata.siteName.slice(1)}}</li>
                        <li><strong>Url:</strong> <a :href="'http://' + viewWebsiteModal.modaldata.siteUrl">{{viewWebsiteModal.modaldata.siteUrl}}</a></li>
                        <li><strong>Service for:</strong> {{viewWebsiteModal.modaldata.serviceFor.toUpperCase()}}</li>
                    </ul>
                </div>
                <div v-if="viewWebsiteModal.modaldata.Websitehit !== null" class="sup_website_info_section">
                    <div class="sup_section_title">Website Hits:</div>
                    <ul>
                        <li><strong>Current Hits:</strong> {{viewWebsiteModal.modaldata.Websitehit.currentHits}}</li>
                        <li><strong>Total Hits:</strong> {{viewWebsiteModal.modaldata.Websitehit.totalHits}}</li>
                    </ul>
                </div>
                <div v-if="viewWebsiteModal.modaldata.User !== null" class="sup_website_info_section">
                    <div class="sup_section_title"><i class="pi pi-user"></i>User info:</div>
                    <ul>
                        <li><strong>Name:</strong> {{viewWebsiteModal.modaldata.User.name}}</li>
                        <li><strong>Email:</strong> {{viewWebsiteModal.modaldata.User.email}}</li>
                    </ul>
                </div>
                <div v-if="viewWebsiteModal.modaldata.License !== null" class="sup_website_info_section">
                    <div class="sup_section_title"><i class="pi pi-shield"></i>License:</div>
                    <ul>
                        <li><strong>Auth status:</strong> 
                        <span :class="['sub_license_status', viewWebsiteModal.modaldata.License === null ? 'warning' : viewWebsiteModal.modaldata.License.status === 'auth' ? 'success' : 'danger']">{{viewWebsiteModal.modaldata.License === null ? 'Not Varified' : viewWebsiteModal.modaldata.License.status.charAt(0).toUpperCase() + viewWebsiteModal.modaldata.License.status.slice(1)}}</span></li>
                        <li><strong>Zone:</strong> {{viewWebsiteModal.modaldata.License.timeZone}}</li>
                        <li><strong>License Key:</strong> <span class="sup_copy_license" :data-license="viewWebsiteModal.modaldata.License.licenseKey"><input type="text" :value="viewWebsiteModal.modaldata.License.licenseKey" readonly /><i class="pi pi-copy" @click="copyLicense($event)" v-tooltip="'Copy License!'"></i></span></li>
                    </ul>
                </div>
                <div v-if="viewWebsiteModal.modaldata.Payment !== null" class="sup_website_info_section">
                    <div class="sup_section_title"><i class="pi pi-credit-card"></i>Payment:</div>
                    <ul>
                        <li><strong>Coupon Code:</strong> {{viewWebsiteModal.modaldata.Payment.couponCode}}</li>
                        <li><strong>Coupon Discount:</strong> {{viewWebsiteModal.modaldata.Payment.couponType !== 'percentage' ? '-$' : ''}}{{viewWebsiteModal.modaldata.Payment.couponDiscount}}{{viewWebsiteModal.modaldata.Payment.couponType === 'percentage' ? '%' : ''}}</li>
                    </ul>
                </div>
            </div>
        </Dialog>
        <!-- Billing Details Modal -->
        <BillingDetailsModal v-if="addNewStep.billingModal" :visible="addNewStep.billingModal" @hide="closeDialog()" />
        <!-- Billing Details Modal -->
        <!-- Installation Modal Start -->
        <Dialog class="installation_detials_popup" header="Installation Request Details" v-model:visible="installationPopup.isOpen"  :modal="true">
            <div class="up_installation_container">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_name">Name<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.name" id="u_name" type="text" readonly />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_email">Email Address<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.email" id="u_email" type="text" readonly />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="u_siteName">Website Name<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.siteName" id="u_siteName" type="text" readonly />
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_siteUrl">Website Url<span class="text-danger">*</span></label>
                        <InputText v-model="installationPopup.siteUrl" id="u_siteUrl" type="text" readonly />
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="u_siteUrl">Message<span class="text-danger">*</span></label>
                        <Textarea v-model="installationPopup.message" id="install-message" />
                        <small class="p-invalid sup_text_danger text-danger" v-if="installationPopup.messageEmpty">Message is not available.</small>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Submit" icon="pi pi-check" class="p-button-primary p-mr-2" @click="submitInstallationRequest()" autofocus />
                <Button label="Cancel" icon="pi pi-times" @click="installationPopup.isOpen = false" class="p-button-info p-mr-2"/>
            </template>
        </Dialog>
        <!--- View Website Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreWebsite(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
        <!-- All actions notification -->
        <Toast position="top-right" />
        <!-- All actions notification -->
    </div>
</template>

<script>
import { allWebsite, websiteById, websiteExport, searchWebsite, allCustomer, allInvoice,userPortalPackageType,  userPortalCardList, validateCoupon, websiteService, allUser, websiteInstallationGuide } from '../../../../config/appUrls';
import { apiKey, scriptTag } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import { formatDate, usFormatDate, isUrlValid } from '../../../../helper/utility/utility';
import { cryptoSecret } from "../../../../config/constant";
import axios from "axios";
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { reactive, onBeforeMount, computed } from 'vue';
import { useRoute } from "vue-router";
const FileSaver = require('file-saver');
import SimpleCrypto from "simple-crypto-js";
import { ref } from 'vue';
// import router from '../../../../router';
const simpleCrypto = new SimpleCrypto(cryptoSecret);

export default {
    name: 'WebsiteManagement',
    setup(){
        //Route call
        const route = useRoute();

        //Vuex store data call fucntion
        const store = useStore();

        //Toast Message call
        const toast = useToast();

        //User privilages role, UUID and actions store
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });
        
        function reloadPage() {
            window.location.reload();
        }

        const licenseStatuses = ref([
            {name: 'All', code: 'all'},
            {name: 'Auth', code: 'auth'},
            {name: 'UnAuth', code: 'unauth'},
            {name: 'Ban', code: 'ban'},
            {name: 'Expired', code: 'expired'},
            {name: 'Incomplete', code: 'incomplete'}
        ]);

        const licenseStatus = ref()

        const isFreeWebsite = ref([
            {name: 'Yes', code:'yes'},
            {name: 'No', code:'no'}
        ])

        const LicenseIsFree = ref()

        //Website initial data storing state
        const allWebsiteData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            print: false,
            search: null,
            sortBy: 'both',
            dateRange: null,
            userId: null,
            selectedItem: null
        });

        //Website adding and editing website initial state
        const addNewStep = reactive({
            websiteDetails: true,
            payment: false,
            complete: false,
            editWebsiteData: null,
            editWebsitePayment: false,
            billingModal: false
        });

        //Add website form storing object 
        const addwebsiteData = reactive({
            service: '',
            name: '',
            url: '',
            coupon: '',
            disableButton: false,
            licenseCode:'',
            additionalService: '',
            card: '',
            user: '',
            userRoleEmpty: ''
        });

        //Website package storing state
      
        const packageType = reactive({
            prime: [],
            combo: [],
            additionalService: false,
            additional: []
        });

        const couponPayloadData = reactive({
            status : null,
            message : '',
        })

        //Website form validation state
        const addwebsiteDataError = reactive({
            invalidName: false,
            invalidUrl: false,
            additionalService: false,
            userEmpty: false,
        })

        //User initial data storing state
        const allUserData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            userStatus: 'active',
            searchUser: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        })

        //After submit api response store
        const apiResponse = reactive({
            step1Status: '',
            step1Message: '',
            FinalSubmitStatus: '',
            FinalSubmitMessage: '',
        })

        //Website detail add modal state
        const addWebsiteModal = reactive({
            modalState: false,
            modaldata: ''
        })

        //Website detail view modal state
        const viewWebsiteModal = reactive({
            modalState: false,
            modaldata: ''
        })

        //Installation popup managing states
        const installationPopup = reactive({
            isOpen : false,
            name : '',
            email: '',
            siteName: '',
            siteUrl: '',
            message: '',
            messageEmpty: false,
        })

        //Website delete/restore confirmation modal
        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        })

        const websiteSearch = reactive({
            customerUuid : null,
            licenseStatus : null,
            isFree :null,
            dateRange : null
        });

        const allCustomerData = reactive({
            data: '',
            status: ''
        });
        
        //Export type and export event handler
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        //Coditional page action state update to show action conditionally
        const websiteDeactive = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.websitemanagementDeactivate);
        const websiteDeactiveList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.websitemanagementDeactivateList);
        const websiteInvoice = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.websitemanagementViewInvoice);
        const websiteExportList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.websitemanagementExport);

        /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('WebsiteManagement');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();
        
        /**
         * On component get mounted require functions call
         */
        onBeforeMount(async() => {
            allWebsiteData.userId = route.params.id;
            fetchAllWebsite();
            fetchCustomerList();
            fetchPackageType();
            changeInputValue();
            fetchCard();
            fetchAllUser('active');
        });

        /**
         * Fetching all Website from DB
         * intially fetching active Website but this fucntion user can get deactive Website and filter Websites by date and name
         */
        const fetchAllWebsite = async (websiteStatus,load) =>{
            let date = null;
            //Toggle Website status to fetch active/deactive role
            if(load === 'websiteStatus'){
                allWebsiteData.activityStatus = websiteStatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allWebsiteData.search = null;
                allWebsiteData.dateRange = null;
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allWebsiteData.dateRange[0]) + ' ' + formatDate(allWebsiteData.dateRange[1]);
            }

            //API call start
            try{
                allWebsiteData.loading = !allWebsiteData.loading;
                const response = await axios.get( allWebsite, {
                    params: {
                        status: allWebsiteData.activityStatus,
                        search: allWebsiteData.search,
                        userId: allWebsiteData.userId,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allWebsiteData.data = response.data.data.websites;
                allWebsiteData.total = response.data.data.total;
                allWebsiteData.loading = !allWebsiteData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * Website is free status change function
         * @param {UUID} is for unique id of the site
         * @param {isfree} is for the current status of the site
         */
        const switchWebsiteStatus = async (uuid, isfree) => {

            //API call start
            try{
                const options = {
                    method: 'put',
                    url: allWebsite,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        isFree: isfree === false ? true : false,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllWebsite();
            } catch(err){
                console.log('err', err);
            }
        }

        /**
         * Detail website site info show modal preparation function
         * @param {slotdata} comes with site unique id
         */
        const viewWebsiteById = async (slotData) => {
            const response = await axios.get( websiteById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                viewWebsiteModal.modalState = true;
                viewWebsiteModal.modaldata = response.data.data;
            }
        }

        /**
         * Delete/restore website fucntion
         * @param {slotdata} comes with site unique id
         * @param {type} comes with action method
         */
        const deleteAndRestoreWebsite = async (sloteData, type) => {
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: allWebsite,
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllWebsite('active');
            } catch(err){
                console.log(err);
            }
        }

        /**
         * Export website list with search and filtered data
         * @param {type} comes with export file type
         */
        const exportXL = async(load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    // allUserData.limit = "";
                    // allUserData.page = "";
                    // + '&page=' + allUserData.page + '&limit=' + allUserData.limit + '&sortBy=' + allUserData.sortBy + '&sortOrder='+ allUserData.sortOrder
                    //const queryUrl = userExport + '?status=' + allUserData.status + '&searchData' + allUserData.searchUser ;
                    if (allWebsiteData.dateRange !== null) {
                        date = formatDate(allWebsiteData.dateRange[0]) + ' ' + formatDate(allWebsiteData.dateRange[1]);
                    }
                    const response = await axios.get( websiteExport, {
                        responseType: 'arraybuffer',
                        params: {
                            status: allWebsiteData.activityStatus,
                            search: allWebsiteData.search,
                            filterDt: date,
                            exe: load,
                            licenseStatus : licenseStatus.value,
                            customer : websiteSearch.customerUuid,
                            isFree : LicenseIsFree.value
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
            if (load === 'pdf') {
                try{
                    if (allWebsiteData.dateRange !== null) {
                        date = formatDate(allWebsiteData.dateRange[0]) + ' ' + formatDate(allWebsiteData.dateRange[1]);
                    }
                    const response = await axios.get( websiteExport, {
                        responseType: 'arraybuffer',
                        params: {
                            print: allWebsiteData.print,
                            search: allWebsiteData.search,
                            filterDt: date,
                            exe: load,
                            licenseStatus : licenseStatus.value,
                            customer : websiteSearch.customerUuid,
                            isFree : LicenseIsFree.value
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName1 = dirtyFileName.match(regex)[3];
                        var blob1 = new Blob([response.data], { type: 'application/pdf' });
                        FileSaver(blob1, fileName1);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        /**
         * Delete/restore confirmation
         */
        const confimationModal = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        const selectedItem = (e) =>{
            console.log(e);
        }

        //Customer list for new customer search dropdown
        const fetchCustomerList = async() => {
            const response = await axios.get( allCustomer, {
                params: {
                    status: 'active',
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                allCustomerData.data = response.data.data.users;
            }
        }

        /**
         * This function for Search Invoice from header
         */
        const searchData = async() => {
        try {
            //check licence status value, if val=all (call all website) else{}
            if (licenseStatus.value === 'all') {
                fetchAllWebsite();
            } else {
                const filterDt = websiteSearch.dateRange === null? null : formatDate(websiteSearch.dateRange[0]) + ' ' + formatDate(websiteSearch.dateRange[1]);
                const response = await axios.get( searchWebsite, {
                params: {
                    licenseStatus : licenseStatus.value,
                    customer : websiteSearch.customerUuid,
                    isFree : LicenseIsFree.value,
                    fiterDate : filterDt
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
                });
                if (response.data.status === 200) {
                    allWebsiteData.data = response.data.data.websites;
                    allWebsiteData.total = response.data.data.total;
                } 
            }
        } catch (err) {
            console.log('error', err);
        }
        }

        const viewInvoiceById = async(slotData) => {
        try {
            //check licence status value, if val=all (call all website) else{}
            const response = await axios.get( allInvoice, {
            params: {
                wuuId : slotData.uuid
            }, 
            headers: {
                apiKey: apiKey,
                token: store.getters.adminAuthToken
            },
            });
            if (response.data.status === 200) {
                allWebsiteData.data = response.data.data.invoices;
                allWebsiteData.total = response.data.data.total;
                //router.push({ name: 'SuperAdminOrderList', query: {wuuId: slotProps.data.uuid} })
            }
        } catch (err) {
            console.log('error', err);
        }
        }

        /**
         * Coping website license key
         */
        const copyLicense = (e) => {
            let getEle = e.target.previousSibling;
            getEle.select();
            document.execCommand("copy");
        }

         const copyKey = (e) => {
            let code = e.target.nextSibling.value;
            navigator.clipboard.writeText(code).then(function() {
                toast.add({severity:'success', summary:'Licence Key copied!', detail:'Licence Key copied to clipboard.', life: 2000});
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }


        /**
         * Website add step 1 form validation
         */
         const step1Validate = () =>{
            if (addwebsiteData.name.length < 3 || addwebsiteData.name.length > 50) {
                addwebsiteDataError.invalidName = true;
                // document.getElementById("websiteName").focus()
                // alert("THis is validation error");
                // const esp = document.querySelectorAll("#websiteName");

                // if(esp.length <=6){
                //     console.log("Validation issue");
                //     this.websiteName
                // }
            } else {
                addwebsiteDataError.invalidName = false;
            }
            
            if (!isUrlValid(addwebsiteData.url)) {
                addwebsiteDataError.invalidUrl = true;
                // document.getElementById("websiteUrl").focus()
            } else {
                addwebsiteDataError.invalidUrl = false;
            }

            if (!addwebsiteDataError.invalidName && !addwebsiteDataError.invalidUrl) {
                return true;
            } else {
                return false;
            }
        }

        /**
         * Website adding step wise api call
         */
        const WebsiteAddStep = async(steps) => {
            //Service Type Set
            let serviceType = addwebsiteData.service === 'ADA/Cookie Consent/CCPA Bundle' ? 'ada_cookies_privacyForm' : addwebsiteData.service === 'ADA & Cookie Consent Bundle' ? 'ada_cookies' : addwebsiteData.service === 'ADA Widget' ? 'ada' : addwebsiteData.service === 'Cookie Consent Widget' ? 'cookies' : 'privacyForm';
            //Service For Set
            let serviceFor = addwebsiteData.service === 'ADA/Cookie Consent/CCPA Bundle' || addwebsiteData.service === 'ADA & Cookie Consent Bundle' ? 'both' : addwebsiteData.service === 'ADA Widget' ? 'ada' : 'service';
            let isValidate = step1Validate();
            if (steps === 'edit') {
                isValidate = true;
            }
            let getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
           
            if (steps !== 'serviceAdd') {
                if (isValidate) {
                    
                    try{
                        const response = await axios.post( allWebsite, {
                            serviceType: steps !== 'edit' ? serviceType : addNewStep.editWebsiteData.type,
                            serviceFor: steps !== 'edit' ? serviceFor : addNewStep.editWebsiteData.serviceFor,
                            websiteName: steps !== 'edit' ? addwebsiteData.name : addNewStep.editWebsiteData.name,
                            websiteUrl: steps !== 'edit' ? addwebsiteData.url : addNewStep.editWebsiteData.url,
                            couponeCode : (steps === 'step1') ? '' : (addwebsiteData.coupon !== null && couponPayloadData.status === 200) ? addwebsiteData.coupon : 'N/A',
                            timeZone: getTimeZone,
                            user_id : steps !== 'edit' ? addwebsiteData.user : addNewStep.editWebsiteData.user,
                        }, {
                            headers: {
                                apiKey: apiKey,
                                token: store.getters.adminAuthToken
                            },
                        });
                        if (response.data.status === 200) {
                           
                            if(steps === 'step1'){
                                addNewStep.websiteDetails = false;
                                addNewStep.payment = true;
                            } else {                            
                                addNewStep.payment = false;
                                addNewStep.complete = true;
                                let split = scriptTag.split("widgetkey=");
                                addwebsiteData.licenseCode = split[0] + "widgetkey=" + response.data.data.license + split[1];
                                
                            }
                        }
                    } catch (err) {
                        apiResponse.step1Status = err.response.data.status;
                        apiResponse.step1Message = err.response.data.message;
                    }
                }
            } else {
        
                if (addwebsiteData.additionalService !== '') {
                    addwebsiteDataError.additionalService = false;
                    try{
                        const response = await axios.put( websiteService, {
                            serviceType: addwebsiteData.additionalService
                        }, {
                            params: {
                                uuid: addNewStep.editWebsiteData.uuid
                            },
                            headers: {
                                apiKey: apiKey,
                                token: store.getters.adminAuthToken
                            },
                        });
                        if (response.data.status === 200) {
                            addNewStep.payment = false;
                            addNewStep.complete = true;
                            let split = scriptTag.split("widgetkey=");
                            addwebsiteData.licenseCode = split[0] + "widgetkey=" + response.data.data.License.licenseKey + split[1];
                        }
                    } catch (err) {
                        apiResponse.step1Status = err.response.data.status;
                        apiResponse.step1Message = err.response.data.message;
                    } 
                } else {
                    addwebsiteDataError.additionalService = true;
                }
            }
            
        }

        /**
         * Back feature form steps
         */
        const backtoDetails = () => {
            addNewStep.websiteDetails = true;
            addNewStep.payment = false;
        }

        /**
         * Coupon apply on the time payment
         */
        const applyCoupon = async() => {
        try {
            
            const response = await axios.post( validateCoupon, {
                couponeCode: addwebsiteData.coupon
            }, {
                headers: {
                  apiKey: apiKey,
                  token: store.getters.adminAuthToken
                },
            });
          
            couponPayloadData.status = response.data.status;
            couponPayloadData.message = response.data.status === 200 ? response.data.data : response.data.message
        } catch (error) {
            couponPayloadData.status = error.response.data.status;
            couponPayloadData.message = error.response.data.status === 200 ? error.response.data.data : error.response.data.message
            }
        }

        /**
         * Installation guide popup intialization steps
         */
         const openInstallationPopup = () => {
            installationPopup.isOpen = true;
            let UserData = JSON.parse(localStorage.getItem("user-details"));
            installationPopup.name = UserData.name;
            installationPopup.email = UserData.email;
            installationPopup.siteName = addwebsiteData.name;
            installationPopup.siteUrl = addwebsiteData.url;
        }

        /**
         * Installation popup resquest form valition steps
         * Submit installing form API call
         */
         const submitInstallationRequest = async() => {
            if (installationPopup.message === '') {
                installationPopup.messageEmpty = true;
            } else {
                installationPopup.messageEmpty = false;
            }
            if (!installationPopup.messageEmpty) {
                try {
                    const response = await axios.post(websiteInstallationGuide, {
                        name: installationPopup.name,
                        email: installationPopup.email,
                        websiteName: installationPopup.siteName,
                        websiteUrl: installationPopup.siteUrl,
                        message: installationPopup.message,
                    }, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if (response.status === 200) {
                        toast.add({severity:'success', summary: 'Request Sent!', detail: response.data.data, life: 5000});
                        installationPopup.isOpen = false;
                        installationPopup.name = '';
                        installationPopup.email = '';
                        addWebsiteModal.modalState = false;
                        addNewStep.complete = false;
                        addwebsiteData.user = '';
                        addwebsiteData.name = '';
                        addwebsiteData.url = '';
                        addwebsiteData.service = '';
                        installationPopup.message = '';
                        addNewStep.websiteDetails = true;
                        addNewStep.payment = false;
                        addwebsiteData.disableButton = true;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        /**
         * Fatching package types form DB
         */
         const fetchPackageType = async() => {
            const respone = await axios.get( userPortalPackageType,  {
                headers: {
                  apiKey: apiKey,
                  token: store.getters.adminAuthToken
                },
            });
            let serviceType = respone.data.data;
            
            for (let i = 0; i < serviceType.length - 2; i++) {
                packageType.prime.push(serviceType[i]);
            }
            let comboService = serviceType.slice(3,5);
            for (let i = 0; i < comboService.length; i++) {
                packageType.combo.push(comboService[i]);
            }
        }

        /**
         * Fatching default card
         */
        const fetchCard = async() => {
            try{
                const respone = await axios.get( userPortalCardList,  {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                if (respone.data.status === 200) {
                    let card = simpleCrypto.decrypt(respone.data.data);
                    for (let i = 0; i < card.length; i++) {
                        if (card[i].isPrimary) {
                            addwebsiteData.card = card[i];
                        }
                    }
                }
            } catch(err){
                console.error(err);
            }
        }

        const changeInputValue = () => {
            addwebsiteData.disableButton = addwebsiteData.name === '' || addwebsiteData.url === '' ? true : false;
        }

        const userCardStatus = computed(() => store.getters.customerDetails.userCard);
        function closeDialog(){
            fetchCard();
        }

        function closeWebsiteDialog(){
            addWebsiteModal.modalState = false;
            addNewStep.complete = false;
            addwebsiteData.user = '';
            addwebsiteData.name = '';
            addwebsiteData.url = '';
            addwebsiteData.service = '';
            installationPopup.message = '';
            addNewStep.websiteDetails = true;
            addNewStep.payment = false;
            addwebsiteData.disableButton = true;
        }

        const fetchAllUser = async (userStatus, load) => {
            let date = '';
            if(load === 'userStatus'){ // if userStatus toggle user list
                allUserData.userStatus = userStatus === 'active' ? 'de-active' : 'active' ;
            }

            if(load === 'resetFilters'){ // if load resetFilters the reset all api param
                allUserData.searchUser = null;
                allUserData.dateRange = null;
            }

            if (load === 'dateRange') { //if dateRange then update scope variable
                date = formatDate(allUserData.dateRange[0]) + ' ' + formatDate(allUserData.dateRange[1]);
            }

            //start api calling
            try{
                allUserData.loading = !allUserData.loading;
                const response = await axios.get( allUser, {
                    params: {
                        status: allUserData.userStatus,
                        search: allUserData.searchUser,
                        filterDt: date
                    }, 
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                    allUserData.data = response.data.data.users;
                    allUserData.total = response.data.data.total;
                    allUserData.loading = !allUserData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout(); // log out user
                } else {
                    console.error('error', err);
                }
            }
        }


        //Returing variables to template
        return{
            allWebsiteData,
            exportType,
            viewWebsiteModal,
            addWebsiteModal,
            pagePrivilages,
            confirmationModal,
            websiteDeactive,
            websiteDeactiveList,
            websiteInvoice,
            websiteExportList,
            addNewStep,
            addwebsiteData,
            packageType,
            addwebsiteDataError,
            allUserData,
            apiResponse,
            couponPayloadData,
            userCardStatus,
            switchWebsiteStatus,
            viewWebsiteById,
            fetchAllWebsite,
            deleteAndRestoreWebsite,
            confimationModal,
            selectedItem,
            copyLicense,
            usFormatDate,
            searchData,
            fetchCustomerList,
            reloadPage,
            allCustomerData,
            websiteSearch,
            licenseStatuses,
            licenseStatus,
            isFreeWebsite,
            LicenseIsFree,
            installationPopup,
            copyKey,
            viewInvoiceById,
            WebsiteAddStep,
            backtoDetails,
            applyCoupon,
            changeInputValue,
            fetchAllUser,
            openInstallationPopup,
            submitInstallationRequest,
            closeDialog,
            closeWebsiteDialog
        }
    }
}
</script>

<style lang="scss">
.p-tieredmenu{
    width: 141px;
}
.sub_website_view{
    .p-dialog-header-icons{
        display: none;
    }
}
.sup_header_field{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}
.sup_header_field_blank{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 10px;
}
.sup_header_field label{
    font-weight: 600;
    color: #000;
}
span.sup_search_boxx {
    margin-left: 270%;
    margin-top: 22px
}
// .order-data-table .p-datatable-header input,
// .order-data-table .p-datatable-header i{
//        background-color: #4b5850;
//        display: none;
// }
.weblist-top-table-header{
    margin: 0 0 20px 0;
}
.table-border-bottom{
border-bottom: 1px solid #d1d1d1;
padding: 0 0 20px 0;
margin: 0 0 10px 0;
}
input.p-inputtext.p-component.up_copy_license.d-none {
    display: none
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/website/WebsiteList";
@import '../../../../assets/style/component/userportal/pages/addNewSite/AddNewSite';
</style>